<template>
	<form @submit.prevent="submit">
		<div class="space-y-4 text-black text-left">

			<div class="relative">
				<input id="ss-name" name="name" type="text" :class="errors && errors.name ? 'border-red-500' : 'border-gray-300'" placeholder="Name" 
					v-model="fields.name"
					class="w-full text-sm placeholder-transparent bg-white focus:shadow-outline focus:outline-none peer border focus:ring-0 focus:border-black p-2 shadow-inner appearance-none focus-visible:border-black leading-tight"
				>
				<label for="ss-name" 
					class="absolute px-1 text-xs duration-[400ms] transition-all bg-white cursor-text -top-2 left-[5px] peer-focus:bg-white peer-focus:rounded peer-focus:-top-2 peer-focus:left-2 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[8px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent placeholder-shown:p-0 pointer-events-none"
					:class="errors && errors.name ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-700'"
				>Name</label>
				<span 
					class="absolute text-xl right-3 top-2 pointer-events-none"
					:class="errors && errors.name ? 'text-red-500' : 'text-[#9298a5]'"
				>*</span>
				<InputError :field="errors.name" class="mt-1 mb-[10px] text-xs text-red-100"/>
			</div>

			<div class="relative">
				<input id="ss-email" name="email" type="email" :class="errors && errors.email ? 'border-red-500' : 'border-gray-300'" placeholder="Email" 
					v-model="fields.email"
					class="w-full text-sm placeholder-transparent bg-white focus:shadow-outline focus:outline-none peer border focus:ring-0 focus:border-black p-2 shadow-inner appearance-none focus-visible:border-black leading-tight"
				>
				<label for="ss-email" 
					class="absolute px-1 text-xs duration-[400ms] transition-all bg-white cursor-text -top-2 left-[5px] peer-focus:bg-white peer-focus:rounded peer-focus:-top-2 peer-focus:left-2 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[8px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent placeholder-shown:p-0 pointer-events-none"
					:class="errors && errors.email ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-700'"
				>Email</label>
				<span 
					class="absolute text-xl right-3 top-2 pointer-events-none"
					:class="errors && errors.email ? 'text-red-500' : 'text-[#9298a5]'"
				>*</span>
				<InputError :field="errors.email" class="mt-1 mb-[10px] text-xs text-red-100"/>
			</div>

			<div class="relative">
				<input id="ss-telephone" name="telephone" type="tel" :class="errors && errors.telephone ? 'border-red-500' : 'border-gray-300'" placeholder="Telephone" 
					v-model="fields.telephone"
					class="w-full text-sm placeholder-transparent bg-white focus:shadow-outline focus:outline-none peer border focus:ring-0 focus:border-black p-2 shadow-inner appearance-none focus-visible:border-black leading-tight"
				>
				<label for="ss-telephone" 
					class="absolute px-1 text-xs duration-[400ms] transition-all bg-white cursor-text -top-2 left-[5px] peer-focus:bg-white peer-focus:rounded peer-focus:-top-2 peer-focus:left-2 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[8px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent placeholder-shown:p-0 pointer-events-none"
					:class="errors && errors.telephone ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-700'"
				>Telephone</label>
				<span 
					class="absolute text-xl right-3 top-2 pointer-events-none"
					:class="errors && errors.telephone ? 'text-red-500' : 'text-[#9298a5]'"
				>*</span>
				<InputError :field="errors.telephone" class="mt-1 mb-[10px] text-xs text-red-100"/>
			</div>

			<div class="relative">
				<textarea rows="6" id="ss-message" name="message" type="text" :class="errors && errors.message ? 'border-red-500' : 'border-gray-300'" placeholder="Message" 
					v-model="fields.message"
					class="w-full text-sm placeholder-transparent bg-white focus:shadow-outline focus:outline-none peer border focus:ring-0 focus:border-black p-2 shadow-inner appearance-none focus-visible:border-black leading-tight"
				></textarea>
				<label for="ss-message" 
					class="absolute px-1 text-xs duration-[400ms] transition-all bg-white cursor-text -top-2 left-[5px] peer-focus:bg-white peer-focus:rounded peer-focus:-top-2 peer-focus:left-2 peer-focus:px-1 peer-focus:text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:top-[8px] peer-placeholder-shown:bg-transparent placeholder-shown:bg-transparent placeholder-shown:p-0 pointer-events-none"
					:class="errors && errors.message ? 'peer-placeholder-shown:text-red-500' : 'peer-placeholder-shown:text-gray-700'"
				>Message</label>
				<InputError :field="errors.message" class="mt-1 mb-[10px] text-xs text-red-100"/>
			</div>

			<div class="text-center">
				<button type="submit" v-bind:disabled="busy"
					class="block mx-auto bg-[#007CBF] hover:bg-[#707070] duration-200 px-8 py-2 text-xl uppercase text-white focus:outline-none">
					<span v-if="busy">
						<svg class="inline-block w-6 h-6" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
							<defs>
								<linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
									<stop stop-color="#fff" stop-opacity="0" offset="0%"/>
									<stop stop-color="#fff" stop-opacity=".631" offset="63.146%"/>
									<stop stop-color="#fff" offset="100%"/>
								</linearGradient>
							</defs>
							<g fill="none" fill-rule="evenodd">
								<g transform="translate(1 1)">
									<path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
										<animateTransform
											attributeName="transform"
											type="rotate"
											from="0 18 18"
											to="360 18 18"
											dur="0.9s"
											repeatCount="indefinite" />
									</path>
									<circle fill="#fff" cx="36" cy="18" r="1">
										<animateTransform
											attributeName="transform"
											type="rotate"
											from="0 18 18"
											to="360 18 18"
											dur="0.9s"
											repeatCount="indefinite" />
									</circle>
								</g>
							</g>
						</svg>
					</span>
					<span v-else>Inquire</span>
				</button>
			</div>
		</div>
	</form>
</template>

<script>
import hapiMixins from '@juno0424/vue2-hapi';
import {InputError} from '@juno0424/vue2-hapi-inputs';

export default {
	mixins: [hapiMixins],
	components: {InputError},
	name: 'ContactForm',
	data() {
		return {
			name: 'contactForm',
			endpoint: "https://hapiform.sg/api/f6baa129-868a-435a-a76c-e4aaed572767",
			redirectTo: "/thank-you/",
		};
	}
}
</script>
