<template>
	<div id="site-header" class="z-10 shadow-md">
		<div class="container h-full px-4">
			<div class="flex flex-wrap items-center justify-between h-full header-inner">
				<div class="flex items-center justify-center w-full h-full lg:justify-start lg:py-4 lg:w-1/2 lg:py-6">
					<img class="object-contain logo" src="@/assets/logo.png" alt="Bon Industrial Sales">
				</div>
				<div class="flex justify-center w-full mb-4 lg:justify-end lg:w-1/2 text-siteblue lg:mb-0">
					<div class="font-bold leading-snug text-center">
						<span class="hidden text-base leading-normal uppercase lg:text-lg lg:inline-block">Call us now at</span>
						<br class="hidden lg:inline">
						<a href="tel:+63287812740" class="box-border text-xl duration-200 border-b-2 border-transparent lg:text-3xl text-siteRed hover:border-siteRed">
							<IconPhone class="inline pb-1 lg:hidden" />
							8781-2740
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import IconPhone from '@/components/svg/IconPhone.vue'
export default {
	components: {
		IconPhone,
	}
}
</script>

<style scoped>
#site-header {
	@apply sticky top-0;
	/* height: 5rem; */
	background-color: white;
}
@screen lg {
	#site-header {
		height: 5.5rem;
	}
}
@screen xl {
	#site-header {
		height: 7.5rem;
	}
}

.header-inner {
	/* height: 120px; */
}
.logo {
	/* min-height: 3rem;
	max-height: 4rem;
	height: 48px; */
}
</style>
