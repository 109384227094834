<template>
	<div id="site-footer" class="z-0 flex-shrink-0 mt-auto">
		<div class="w-full h-16 -mt-16 pointer-events-none lg:-mt-32 lg:h-32">
			<!-- <img class="w-full h-full" src="@/assets/footer-flair.png"> -->
		</div>

		<div class="container px-4 py-2 text-center text-white lg:py-4">
			<div class="text-xs leading-tight">
				Maintained and developed by <br class="sm:hidden"><a href="https://www.amph.com.ph/" class="hover:underline">AMPH Advertising Agency Inc.</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style>
#site-footer {
	background-color: #282828;
}
</style>
