<template>
	<div id="mainpage" class="flex flex-col min-h-screen home">
		<SiteHeader />

		<div class="banner-section">
			<div class="container flex items-center w-full h-full px-4 inner">
				<div class="w-full font-black leading-snug text-center text-white banner-text">
					<h1>Your one-stop shop for all <br>your metal cutting needs.</h1>
				</div>
			</div>
		</div>

		<div class="about-section bg-siteGray">
			<div class="container flex flex-wrap">
				<div class="w-full px-4 py-10 text-sm lg:text-lg lg:w-7/12">
					<h2 class="mb-8 text-siteGray-2">About Us</h2>
					<p class="mb-4">Established in 1977, Bon Industrial Sales began its journey primarily as a supplier of hardware and construction materials. Over the years, seeing the growing demand in metal cutting tools, Bon Industrial diversified by importing high-quality brands. All the while upholding a reputation for providing quality products and service in supplying hardware and construction materials.</p>
					<p class="mb-4">Bon Industrial aims to be the preferred supplier by providing quality products that combine with performance, timely delivery, and after sales support. By keeping abreast with the latest technology, Bon Industrial equips their sales and technical team with continuous training to gain deeper understanding and competence in technical and engineering support. The company also seeks to introduce and promote a wide range of product, offering special tools to better suit the clients’ requirements and application.</p>
					<p class="mb-4">For over 40 years, Bon Industrial Sales has grown to be one of the leading providers in machine and metal cutting tools, machine accessories, engineering supplies, and hardware products.</p>
					<div class="flex items-center justify-center py-8">
						<div class="flex flex-wrap items-center justify-center w-full lg:w-8/12">
							<img src="@/assets/logo-kyocera.png" alt="Kyocera" class="p-2">
							<img src="@/assets/logo-sutton.png" alt="Sutton" class="p-2">
							<img src="@/assets/logo-maford.png" alt="MA Ford" class="p-2">
							<img src="@/assets/logo-yamawa.png" alt="Yamawa" class="p-2">
							<img src="@/assets/logo-vertex.png" alt="Vertex" class="p-2">
							<img src="@/assets/logo-besdia.png" alt="Besdia" class="p-2">
							<img src="@/assets/logo-honsberg.png" alt="Honsberg" class="p-2">
							<img src="@/assets/logo-leitz.png" alt="Leitz" class="p-2">
							<img src="@/assets/logo-noga.png" alt="Noga" class="p-2">
							<img src="@/assets/logo-ohmi.png" alt="Ohmi" class="p-2">
							<img src="@/assets/logo-okabe.png" alt="Okabe" class="p-2">
							<img src="@/assets/logo-precision.png" alt="Precision" class="p-2">
							<img src="@/assets/logo-vallorbe.png" alt="Vallorbe" class="p-2">
							<img src="@/assets/logo-waytrain.png" alt="Way Train" class="p-2">
							<img src="@/assets/logo-wiha.png" alt="Wiha" class="p-2">
						</div>
					</div>
				</div>
				<div class="w-full px-4 py-10 text-center text-white lg:w-5/12 bg-siteRed-2">
					<h2 class="mb-8">Inquire Now!</h2>
					<div class="mb-10">
						<ContactForm />
						<!-- <iframe allowTransparency="true" style="min-height:300px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-5931939.html"><p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax etc)</p></iframe> -->
					</div>
					<h2 class="mb-8">Contact Information</h2>
					<div class="text-sm lg:text-lg">
						<h3 class="font-bold"><IconMarker class="inline pb-1" />Address</h3>
						<p class="mb-8">35 Macopa St., Brgy. Sto. Domingo, <br>Sta. Mesa Heights, Quezon City</p>
						
						<h3 class="font-bold"><IconMail class="inline pb-1" />E-mail</h3>
						<a class="box-border inline-block mb-8 duration-200 border-b-2 border-transparent hover:border-white" href="mailto:bonindustrial@gmail.com">bonindustrial@gmail.com</a>
						
						<h3 class="font-bold"><IconPhone class="inline pb-1" />Contact Numbers</h3>
						<div class="inline-block">
							<a class="box-border inline-block duration-200 border-b-2 border-transparent hover:border-white" href="tel:+63287812740">8781-2740</a>
							<span class="mx-2">|</span>
							<a class="box-border inline-block duration-200 border-b-2 border-transparent hover:border-white" href="tel:+63287493672">8749-3672</a>
							<br>
							<a class="box-border inline-block duration-200 border-b-2 border-transparent hover:border-white" href="tel:+63287400458">8740-0458</a>
							<span class="mx-2">|</span>
							<a class="box-border inline-block duration-200 border-b-2 border-transparent hover:border-white" href="tel:+63287493754">8749-3754</a>
						</div>
						
					</div>

				</div>
			</div>
		</div>

		<div class="feat-products-section">
			<div class="container px-4 py-10">
				<h2 class="mb-8 text-siteGray-2">Our Products</h2>
				<carousel
					:dots="true"
					:nav="false"
					:loop="false"
					:autoplay="true"
					:autoHeight="false"
					:autoplaySpeed="500"
					:autoplayTimeout="5000"
					:margin="32"
					:responsive="{
						0: {
							items:1,
						},
						1024: {
							items:3,
						},
					}">
					<div class="box-border bg-white border border-transparent slide-container">
						<div class="box-border mb-4 border border-siteGray-2">
							<img class="object-cover object-center" src="@/assets/feat-kyocera.jpg" alt="Kyocera">
						</div>
						<div class="font-bold text-center">
							Kyocera
						</div>
					</div>
					<div class="box-border bg-white border border-transparent slide-container">
						<div class="box-border mb-4 border border-siteGray-2">
							<img class="object-cover object-center" src="@/assets/feat-maford.jpg" alt="MA Ford">
						</div>
						<div class="font-bold text-center">
							MA Ford
						</div>
					</div>
					<div class="box-border bg-white border border-transparent slide-container">
						<div class="box-border mb-4 border border-siteGray-2">
							<img class="object-cover object-center" src="@/assets/feat-sutton.jpg" alt="Sutton">
						</div>
						<div class="font-bold text-center">
							Sutton
						</div>
					</div>
					<div class="box-border bg-white border border-transparent slide-container">
						<div class="box-border mb-4 border border-siteGray-2">
							<img class="object-cover object-center" src="@/assets/feat-vertex.jpg" alt="Vertex">
						</div>
						<div class="font-bold text-center">
							Vertex
						</div>
					</div>
					<div class="box-border bg-white border border-transparent slide-container">
						<div class="box-border mb-4 border border-siteGray-2">
							<img class="object-cover object-center" src="@/assets/feat-yamawa.jpg" alt="Yamawa">
						</div>
						<div class="font-bold text-center">
							Yamawa
						</div>
					</div>

				</carousel>
			</div>
		</div>

		<div class="products-section bg-siteGray">
			<div class="container px-4 py-10">
				<h2 class="mb-8 text-siteGray-2">Other Products</h2>
				<div class="">
					<ul class="mb-8 text-sm list-disc list-inside lg:text-lg">
						<li>Bandsaw Blades</li>
						<li>Bandsaw Machines</li>
						<li>Carbide Inserts</li>
						<li>Carbide Burs</li>
						<li>Deburring Holdings System</li>
						<li>Carbide Drill</li>
						<li>Carbide Endmill</li>
						<li>Hand Tools</li>
						<li>Machine Tools and Accessories</li>
						<li>Screw Driver Bits</li>
					</ul>
				</div>
				<carousel
					:dots="true"
					:nav="false"
					:loop="false"
					:autoplay="true"
					:autoHeight="false"
					:autoplaySpeed="500"
					:autoplayTimeout="5000"
					:margin="32"
					:responsive="{
						0: {
							items:1,
						},
						1024: {
							items:3,
						},
					}">
					<div class="box-border bg-white border border-transparent slide-container">
						<div class="box-border border border-siteGray-2">
							<img class="object-cover object-center" src="@/assets/products001.jpg" alt="">
						</div>
					</div>
					<!-- <div class="box-border bg-white border border-transparent slide-container">
						<div class="box-border border border-siteGray-2">
							<img class="object-cover object-center" src="@/assets/products002.jpg" alt="">
						</div>
					</div>
					<div class="box-border bg-white border border-transparent slide-container">
						<div class="box-border border border-siteGray-2">
							<img class="object-cover object-center" src="@/assets/products003.jpg" alt="">
						</div>
					</div>
					<div class="box-border bg-white border border-transparent slide-container">
						<div class="box-border border border-siteGray-2">
							<img class="object-cover object-center" src="@/assets/products004.jpg" alt="">
						</div>
					</div> -->
					<div class="box-border bg-white border border-transparent slide-container">
						<div class="box-border border border-siteGray-2">
							<img class="object-cover object-center" src="@/assets/products005.jpg" alt="">
						</div>
					</div>
					<!-- <div class="box-border bg-white border border-transparent slide-container">
						<div class="box-border border border-siteGray-2">
							<img class="object-cover object-center" src="@/assets/products006.jpg" alt="">
						</div>
					</div>
					<div class="box-border bg-white border border-transparent slide-container">
						<div class="box-border border border-siteGray-2">
							<img class="object-cover object-center" src="@/assets/products007.jpg" alt="">
						</div>
					</div> -->

				</carousel>
			</div>
		</div>

		<div class="brands-section">
			<div class="container px-4 py-10">
				<h2 class="mb-8 text-siteGray-2">Our Brands</h2>

				<div class="">
					<ul class="mb-8 text-sm list-disc list-inside lg:text-lg">
						<li>Besdia</li>
						<li>Honsberg</li>
						<li>Kyocera</li>
						<li>Leitz</li>
						<li>MA Ford</li>
						<li>Noga</li>
						<li>Precision Twist Drills </li>
						<li>Sutton Tools</li>
						<li>Ohma</li>
						<li>Okabe</li>
						<li>Vallorbe</li>
						<li>Vertex</li>
						<li>Yamawa</li>
						<li>Wiha</li>
						<li>Way Train</li>
					</ul>
				</div>

			</div>
		</div>

		<div class="map-section">
			<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1930.2057362856042!2d121.0018578!3d14.6325689!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b0ee49e93d0b%3A0xc417f05a37f5369!2sBon%20Industrial%20Sales!5e0!3m2!1sen!2sph!4v1623741661942!5m2!1sen!2sph" class="w-full h-full" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
		</div>

		<SiteFooter />
	</div>
</template>

<script>
// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
import IconPhone from '@/components/svg/IconPhone.vue'
import IconMarker from '@/components/svg/IconMarker.vue'
import IconMail from '@/components/svg/IconMail.vue'
import ContactForm from '@/components/ContactForm.vue'

export default {
	name: 'Home',
	components: {
		SiteHeader,
		SiteFooter,
		IconPhone,
		IconMarker,
		IconMail,
		ContactForm,
	},
}
</script>

<style>
.banner-section {
	height: 300px;
	position: relative;
}
@screen sm {
	.banner-section {
		height: 400px;
	}
}
@screen lg {
	.banner-section {
		height: 600px;
	}
}

.banner-section::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 50%;
	bottom: 0;
	left: 0;
	z-index: -1;
	@apply bg-siteGray;
}
	
.banner-section .inner {
	background-image: url('~@/assets/banner-bg2.jpg');
	@apply bg-center;
	@apply bg-cover;
	@apply bg-no-repeat;
}

.banner-text {
	text-shadow: 0px 3px 3px rgba(0,0,0,0.55);
	font-size: 28px;
}
@screen lg {
	.banner-text {
		font-size: 44px;
	}
}

h2 {
	font-size: 28px !important;
	font-weight: bold !important;
	@apply text-center;
	@apply leading-snug;
}
@screen lg {
	h2 {
		font-size: 32px !important;
		text-align: inherit;
	}
}

@screen lg {
	.feat-products-section ul {
		column-count: 2;
	}
	.products-section ul {
		column-count: 2;
	}
	.products-section .owl-stage {
		@apply mx-auto;
	}
}

.brands-section ul {
	column-count: 2;
}
@screen lg {
	.brands-section ul {
		column-count: 4;
	}
}

.map-section {
	height: 400px;
}
@screen lg {
	.map-section {
		height: 500px;
	}
}
</style>
